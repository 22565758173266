import { SentenceType, TagType } from "App.types";
import React, { FC, useMemo, useState } from "react";
import { flatten } from "lodash";
import WordWithTooltip from "Components/WordWithTooltip";
import { Draggable, Droppable } from "react-beautiful-dnd";
import cx from "classnames";
import styles from "./SentenceDragDrop.module.scss";
import { Tooltip } from "antd";
import { isMobile } from "react-device-detect";

const SentenceDragDrop: FC<{
  dropTags: { [key: string]: { text: string; draggableId: string } | undefined };
  sentence: SentenceType;
  activeWordIdx?: number;
  selectedTag?: TagType;
  onDropClick?: Function;
  setSelectedTag?: Function;
  allTags?: TagType[];
  showTranslate?: boolean;
  wrap?: boolean;
}> = ({ wrap, showTranslate = true, allTags = [], setSelectedTag, onDropClick, selectedTag, sentence, activeWordIdx, dropTags }) => {
  const { tags, text, translate, phrases } = sentence;

  const [selectedWords, setSelectedWords] = useState<number[]>([]);

  const items = useMemo(() => {
    let index = 0;

    return flatten(
      tags.map((t, idx) => [
        { word: idx === 0 || text.includes(`${tags[idx - 1].word}${t.word}`) ? "" : " " },
        {
          ...t,
          idx,
          wordIdx: idx === 0 || text.includes(`${tags[idx - 1].word}${t.word}`) ? index : ++index,
          phrase: phrases.find((phrase) => phrase.positionsIdx.includes(idx)),
        },
      ]),
    ).filter((el) => el.word);
  }, [phrases, tags, text]);

  return (
    <span className={cx(styles.SentenceDragDrop, { [styles.SentenceDragDrop__asBlock]: wrap })}>
      <Tooltip title={showTranslate && isMobile ? translate : undefined} trigger={["click"]}>
        <span>
          {items.map((el: any, index) =>
            el.isMasked ? (
              <Droppable droppableId={`${el.id}`} key={`${el.id}${dropTags[`${el.id}`]?.draggableId}`} direction={"horizontal"}>
                {(provided, snapshot) => {
                  const isRight = el.word === dropTags[`${el.id}`]?.text;

                  return (
                    <span
                      onClick={(e) => {
                        if (selectedTag) e.stopPropagation();
                        selectedTag && `${selectedTag.id}` !== dropTags[`${el.id}`]?.draggableId && onDropClick?.(`${el.id}`);
                      }}
                      className={cx(styles.drop, {
                        [styles.drop__hover]: selectedTag,
                        [styles.drop__hovered]: snapshot.isDraggingOver,
                        [styles.drop__filled]: dropTags[`${el.id}`] && !snapshot.draggingFromThisWith,
                        [styles.drop__completed]: isRight,
                      })}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      // isDraggingOver={snapshot.isDraggingOver}
                    >
                      {dropTags[`${el.id}`] && (
                        <Draggable isDragDisabled={isRight} index={0} draggableId={dropTags[`${el.id}`]?.draggableId || ""}>
                          {(draggableProvided, snapshot) => (
                            <span
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              onClick={(e) => {
                                if (showTranslate) e.stopPropagation();

                                if (isRight) return;
                                const tag = allTags.find((t) => `${t.id}` === dropTags[`${el.id}`]?.draggableId);
                                setSelectedTag?.((prev: TagType) => (prev === tag ? undefined : tag));
                              }}
                            >
                              <span
                                className={cx(styles.tag, {
                                  [styles.tag__selected]: dropTags[`${el.id}`]?.draggableId === `${selectedTag?.id}`,
                                  [styles.tag__wrong]: !snapshot.isDragging && !isRight,
                                })}
                              >
                                {dropTags[`${el.id}`]?.text}
                              </span>
                            </span>
                          )}
                        </Draggable>
                      )}
                      {provided.placeholder}
                    </span>
                  );
                }}
              </Droppable>
            ) : !showTranslate || (showTranslate && !isMobile) ? (
              <WordWithTooltip
                show={() => setSelectedWords(el.phrase?.positionsIdx || [el.idx])}
                hide={() => setSelectedWords([])}
                isActive={el.wordIdx === activeWordIdx || selectedWords.includes(el.idx)}
                key={index}
                {...el}
                phrase={el.phrase?.text}
              />
            ) : (
              <span>{el.word}</span>
            ),
          )}
        </span>
      </Tooltip>

      {showTranslate && !isMobile && <span className={styles.translate}>{translate}</span>}
    </span>
  );
};

export default SentenceDragDrop;
