import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Rate } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LessonType } from "App.types";

type Props = {
  lesson?: LessonType;
  onRepeat: Function;
  onContinue?: Function;
};

const useProgress = ({ lesson, onRepeat }: Props) => {
  const { data: progress, refetch } = useSuspenseQuery({
    queryKey: ["progress", lesson?.id],
    //enabled: !!lesson,
    queryFn: () => API.progress.getForLesson(lesson?.id ?? 0),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    gcTime: 0,
  });

  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    if (progress && !progress.length) {
      setShowModal(false);
    }
    // setShowModal((prev) => (prev ? !!progress?.length : prev));
  }, [progress]);

  const { course } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (!showModal) return;

    const hasProgress = progress?.some((pr) => pr.percent);
    const completed = progress.find((pr) => pr.status === "completed");

    if (hasProgress && !completed) {
      modal.confirm({
        title: "Задание осталось незавершенным",
        icon: <ExclamationCircleOutlined />,
        content: "",
        okText: "Продолжить",
        cancelText: "Начать с начала",
        onOk: () => {
          setShowModal(false);
          // onContinue?.(lessonProgress);
          // setPercent(lessonProgress.percent || 0);
          // setActiveTaskIdx(lesson?.tasks[0] === TaskType.Mixed ? 0 : tasks.findIndex((task) => task === progress?.type));
          //onNext(findIndex(sentences, (s) => s.id === progress.sentence.id));
        },
        onCancel: () => {
          setShowModal(false);
          onRepeat();
        },
      });
    }

    if (completed) {
      modal.confirm({
        title: "Задание уже пройдено",
        icon: <ExclamationCircleOutlined />,
        content: <Rate allowHalf disabled defaultValue={completed.stars} />,
        cancelText: "Пройти заново",
        okText: "Вернуться в задания",
        onOk: () => (state ? navigate(-1) : navigate(`/course/${course}`, { state: { lessonId: lesson?.id } })),
        onCancel: () => {
          setShowModal(false);

          onRepeat();
        },
      });
    }
  }, [state, course, modal, navigate, onRepeat, progress, showModal, lesson?.id]);

  return { progress, contextHolder, refetch, showProgressModal: showModal };
};

export default useProgress;
